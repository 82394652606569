import React, { useEffect, useState } from 'react';

const OutstreamVideoAd = () => {
  // Automatically toggle between the zone IDs on each component mount
  const zoneIds = ["5445488", "5472024"];
  const [currentZoneId] = useState(() => {
    // Randomly select a zone ID or alternate between them
    return zoneIds[Math.floor(Math.random() * zoneIds.length)];
  });

  useEffect(() => {
    // Load the ad script
    const script = document.createElement('script');
    script.src = "https://a.magsrv.com/ad-provider.js";
    script.async = true;
    script.type = "application/javascript";
    document.body.appendChild(script);

    // Initialize the AdProvider once the script is loaded
    script.onload = () => {
      if (window.AdProvider) {
        window.AdProvider.push({ serve: {} });
      }
    };

    // Clean up script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [currentZoneId]); // Re-run the effect if the zone ID changes

  return (
    <div className="outstream-video-ad">
      <ins className="eas6a97888e37" data-zoneid={currentZoneId}></ins>
    </div>
  );
};

export default OutstreamVideoAd;
