import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../GameStyles.css';
import gameScreenshot1 from '../static/neon_horizon_screenshot.jpg';
import gameScreenshot2 from '../static/neon_horizon_screenshot_2.jpg';
import gameScreenshot3 from '../static/neon_horizon_screenshot_3.jpg';
import gameScreenshot4 from '../static/final_void/FV_screenshot_1.jpg';
import gameScreenshot5 from '../static/final_void/FV_screenshot_2.jpg';
import gameScreenshot6 from '../static/final_void/FV_screenshot_3.jpg';
import ArrowDashSS from '../static/arrow-dash/arrow-dash-ss1.jpg'
import ArrowDashSS2 from '../static/arrow-dash/arrow-dash-ss2.jpg'
import asteroidsScreenshot1 from '../static/space-breaker/space-breaker-screenshot-1.jpg'
import asteroidsScreenshot2 from '../static/space-breaker/space-breaker-screenshot-2.jpg'
import pilotScreenshot1 from '../static/endless-space-pilot/endless-space-pilot-screenshot-1.jpg'
import pilotScreenshot2 from '../static/endless-space-pilot/endless-space-pilot-screenshot-2.jpg'
import paddleScreenshot1 from '../static/paddle-war/paddle-war-screenshot-1.jpg'
import paddleScreenshot2 from '../static/paddle-war/paddle-war-screenshot-2.jpg'
import { Banner } from 'exoclick-react';
import OutstreamVideoAd from './OutstreamVideoAd';
import { Helmet } from 'react-helmet-async';


const games = [
  {
    name: 'NEON HORIZON',
    description: 'Enter a neon-lit, synthwave world in this endless runner. Dodge obstacles at high speeds while enjoying retro visuals and a pulsating soundtrack. Test your reflexes!',
    url: 'https://play.google.com/store/apps/details?id=com.neonhorizon.release&hl=en_US',
    releaseDate: '07-07-2024',
    playOnline: '/neon-horizon',
    tags: [
      'endless runner--',
      'action--',
      'retro--'
    ],
    screenshots: [
      gameScreenshot1,
      gameScreenshot2,
      gameScreenshot3
    ]
  },
  {
    name: 'Final Void',
    description: 'Final Void is a dark 2D platformer where you play as a wizard fighting eldritch monsters. Explore eerie levels and uncover relics in this challenging 3-level demo.',
    url: 'https://play.google.com/store/apps/details?id=com.finalvoid.mobile&pcampaignid=web_share',
    releaseDate: '09-25-2024',
    playOnline: '/final-void-online',
    tags: [
      'platformer--',
      'action--',
      'adventure--'
    ],
    screenshots: [
      gameScreenshot4,
      gameScreenshot5,
      gameScreenshot6
    ]
  },
  {
    name: 'Arrow Dash',
    description: 'Dodge the pillars and hit the targets! Survive as long as you can!',
    releaseDate: '10-14-2024',
    playOnline: '/arrow-dash',
    tags: [
      'casual--',
      'arcade--'
    ],
    screenshots: [
      ArrowDashSS,
      ArrowDashSS2
    ]
  },

  {
    name: 'Space Breaker',
    description: 'Avoid and destroy asteroids, survive as long as you can!',
    releaseDate: '11-08-2024',
    playOnline: '/space-breaker',
    screenshots: [
      asteroidsScreenshot1,
      asteroidsScreenshot2
    ],
    tags: [
      'arcade--'
    ],
  },
  {
    name: 'Paddle War',
    description: 'A classic we all know and love',
    releaseDate: '11-08-2024',
    playOnline: '/space-breaker',
    screenshots: [
      paddleScreenshot1,
      paddleScreenshot2
    ],
    tags: [
      'arcade--'
    ],
  },
  {
    name: 'Endless Space Pilot',
    description: 'Move your cursor to contorl the ship, avoid the obstacles in your path!',
    releaseDate: '11-08-2024',
    playOnline: '/space-breaker',
    screenshots: [
      pilotScreenshot1,
      pilotScreenshot2
    ],
    tags: [
      'arcade--',
      'endless runner--'
    ],
  },
  // Add more games as needed
];

const GamesPage = () => {

  const sortedGames = [...games].sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));

  const zonIDs = [
    '5443852', // check
    '5443364', // check
    '5443366', // check
  ]

  const getRandomZoneId = () => {
    const randomIndex = Math.floor(Math.random() * zonIDs.length);
    return zonIDs[randomIndex];
  };

  const [randomZoneId, setRandomZoneId] = useState(getRandomZoneId());

  return (
    <div className='GamesPage'>
            <Helmet>
                <title>Games by Dissonant Druid - Explore Our Game Collection</title>
                <meta property="og:title" content="Games by Dissonant Druid - Explore Our Game Collection" />
                <meta 
                    property="og:description" 
                    content="Discover a variety of games crafted by Dissonant Druid. From retro-inspired challenges to dark fantasy adventures, find a game for every taste!" 
                />
                <meta property="og:image" content="/images/games/games-page-banner.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dissonantdruid.com/games" />
            </Helmet>
      <h1 className='PageTitle'>FREE GAMES</h1> 
      
      {sortedGames.map((game, index) => (
        <div key={index}>
          {/* Game Section */}
          <div className='GameSection'>
            <h1 className='GameTitle'>{game.name}</h1>
            <p className='GameDescription'>{game.description}</p>
            {game.screenshots && (
            <div className='Screenshots'>
              {game.screenshots.map((screenshot, idx) => (
                <img key={idx} src={screenshot} alt={`Screenshot ${idx + 1}`} className='Screenshot' />
              ))}
            </div>
          )}
            
            <div className='ButtonContainer'>
            {game.url && (
              <a href={game.url} target="_blank" rel="noopener noreferrer" className='PlayStoreLink'>
                <button className='PlayStoreButton'>Get it on Google Play</button>
              </a>
            )}
              {game.playOnline && (
                <Link to={game.playOnline}>
                  <button className="PlayOnlineButton">Play it online!</button>
                </Link>
              )}
            </div>
            <div className='tagContainer'>
              <div>tags:</div>
            {game.tags}
            </div>
          </div>

          {/* Always render Banner after each game */}
          <div className='AdContainer'>
            <Banner zoneId={5472050} />
            
          </div>
        </div>
      ))}
      <OutstreamVideoAd />
    </div>
  );
};

export default GamesPage;
