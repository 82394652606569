import React, { useState } from 'react';
import '../TesterSignup.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const TesterSignUpSection = () => {
  const [email, setEmail] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|outlook\.com)$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert("Please enter a valid Gmail address.");
      return;
    }

    try {
      await addDoc(collection(db, 'testerSignUps'), {
        email: email,
        timestamp: serverTimestamp(),
      });
      alert(`Thank you for signing up, ${email}!`);
      setEmail('');
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("There was an error submitting your email. Please try again.");
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="testerSignUpSection">
      <button className="closeButton" onClick={handleClose}>X</button>
      <h1 className='becomeText'>Join the mailing list</h1>
      <h3>
        Enter your email to recieve all the latest news <br /> 
        or to be include in testing future games <br /><br />
      </h3>
      <form className="signUpForm" onSubmit={handleSubmit}>
        <div className="formGroup">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submitButton">Submit</button>
      </form>
    </div>
  );
};

export default TesterSignUpSection;
