

export const silentHill2RemakeQuiz = {
    quizPath: 'silenthill',
    quizHeader: "Think you know everything about Silent Hill 2? Test your knowledge and challenge yourself with this quiz to see how well you truly remember the haunting details of this classic survival horror game.",
    image: "/images/quizzes/silent-hill-2.jpg",
    data: [
    {
        question: "Who is the main protagonist of Silent Hill 2?",
        options: ["James Sunderland", "Harry Mason", "Heather Mason", "Pyramid Head"],
        correctAnswer: "James Sunderland"
    },
    {
        question: "What is the name of James Sunderland's deceased wife?",
        options: ["Mary", "Maria", "Angela", "Laura"],
        correctAnswer: "Mary"
    },
    {
        question: "Which iconic monster appears frequently in Silent Hill 2 and symbolizes James's guilt?",
        options: ["Nurse", "The Butcher", "Pyramid Head", "Twin Victim"],
        correctAnswer: "Pyramid Head"
    },
    {
        question: "What is the name of the first character that James meets upon entering silent hill?",
        options: ["Maria", "Mary", "Angela", "Laura"],
        correctAnswer: "Angela"
    },
    {
        question: "How many years ago did James' wife die?",
        options: ["4", "2", "3", "1"],
        correctAnswer: "The fog-covered town of Silent Hill"
    },
    {
        question: "What is the first major location James explores in silent hill?",
        options: ["Toluca Prison", "Lakeview Hotel", "Blue Creek Apartments", "Wood Side Apartments"],
        correctAnswer: "The fog-covered town of Silent Hill"
    },
    {
        question: "What is the name of the club that James and Maria pass through?",
        options: ["Heavens Night", "Club Inferno", "Paradise Lost", "Moonlight Lounge"],
        correctAnswer: "The fog-covered town of Silent Hill"
    },
    {
        question: "What is Eddie eating when James finds him in the bowling alley?",
        options: ["Pizza", "Burger", "Hot Dog", "Fries"],
        correctAnswer: "Pizza"
    },
    {
        question: "What state is Silent Hill located?",
        options: ["Vermont", "Maine", "Oregon", "New York"],
        correctAnswer: "Maine"
    },

    {
        question: "What item must James examine multiple times to trigger certain dialogue that hints at his guilt?",
        options: ["Mary's letter", "Photo of Mary", "The knife given by Angela", "Radio"],
        correctAnswer: "The knife given by Angela"
    }
]
    
};

export const minecraftQuiz = {
    quizPath: 'minecraft',
    quizHeader: "Are you a true Minecraft expert? Put your knowledge to the test with our challenging quiz and prove you know everything about the world of blocks, mobs, and crafting!",
    image: '/images/quizzes/mincraft.jpg',
    data: [
    {
        question: "What is the name of the dimension where the Ender Dragon resides?",
        options: ["The Nether", "The End", "The Overworld", "The Void"],
        correctAnswer: "The End"
    },
    {
        question: "Which item is required to craft a Nether portal?",
        options: ["Obsidian", "End Stone", "Bedrock", "Diamond Block"],
        correctAnswer: "Obsidian"
    },
    {
        question: "What do you need to tame a wolf?",
        options: ["Bone", "Raw Meat", "String", "Fish"],
        correctAnswer: "Bone"
    },
    {
        question: "Which hostile mob explodes when it gets close to the player?",
        options: ["Zombie", "Creeper", "Skeleton", "Enderman"],
        correctAnswer: "Creeper"
    },
    {
        question: "What is the maximum number of items that can fit in a stack (most items) in Minecraft?",
        options: ["16", "32", "64", "128"],
        correctAnswer: "64"
    },
    {
        question: "What enchantment allows you to breathe underwater?",
        options: ["Aqua Affinity", "Respiration", "Depth Strider", "Feather Falling"],
        correctAnswer: "Respiration"
    },
    {
        question: "Which block is needed to make a beacon function?",
        options: ["Emerald Block", "Gold Block", "Iron Block", "Diamond Block"],
        correctAnswer: "Iron Block"
    },
    {
        question: "What item can be used to cure a zombie villager?",
        options: ["Golden Apple", "Potion of Healing", "Bread", "Enchanted Book"],
        correctAnswer: "Golden Apple"
    },
    {
        question: "What tool do you use to collect honey from a beehive without angering the bees?",
        options: ["Bucket", "Glass Bottle", "Shears", "Empty Hand"],
        correctAnswer: "Glass Bottle"
    },
    {
        question: "Which of these mobs is passive and won't attack the player?",
        options: ["Blaze", "Ghast", "Sheep", "Spider"],
        correctAnswer: "Sheep"
    }
]
};


export const gamingHistoryQuiz = {
    quizPath: 'gaminghistory',
    quizHeader: "Think you know the history of video games? Test your knowledge with this quiz and see how much you really know about the iconic moments that shaped the gaming world!",
    image: "/images/quizzes/gaming-history.jpg",
    data: [
        {
            question: "What is considered the first commercially successful arcade video game?",
            options: ["Pong", "Space Invaders", "Asteroids", "Pac-Man"],
            correctAnswer: "Pong"
        },
        {
            question: "Which year was the original Nintendo Entertainment System (NES) released in North America?",
            options: ["1983", "1985", "1987", "1990"],
            correctAnswer: "1985"
        },
        {
            question: "Which company developed the game 'Space Invaders'?",
            options: ["Atari", "Namco", "Taito", "Sega"],
            correctAnswer: "Taito"
        },
        {
            question: "Who is the creator of the Mario franchise?",
            options: ["Shigeru Miyamoto", "Hideo Kojima", "Yu Suzuki", "Satoru Iwata"],
            correctAnswer: "Shigeru Miyamoto"
        },
        {
            question: "What was the first home console to feature an online gaming network?",
            options: ["Dreamcast", "PlayStation 2", "Xbox", "GameCube"],
            correctAnswer: "Dreamcast"
        },
        {
            question: "Which game is credited with pioneering the first-person shooter (FPS) genre?",
            options: ["Wolfenstein 3D", "Doom", "Quake", "GoldenEye 007"],
            correctAnswer: "Wolfenstein 3D"
        },
        {
            question: "Which of the following is the best-selling video game console of all time?",
            options: ["PlayStation 2", "Nintendo Switch", "Xbox 360", "PlayStation 4"],
            correctAnswer: "PlayStation 2"
        },
        {
            question: "What year was the iconic game 'The Legend of Zelda: Ocarina of Time' released?",
            options: ["1996", "1997", "1998", "1999"],
            correctAnswer: "1998"
        },
        {
            question: "What was the name of the ill-fated gaming console released by Atari in 1993?",
            options: ["Atari Jaguar", "Atari Lynx", "Atari Panther", "Atari Falcon"],
            correctAnswer: "Atari Jaguar"
        },
        {
            question: "Which game is often credited with creating the 'battle royale' genre?",
            options: ["PUBG", "Fortnite", "DayZ", "H1Z1"],
            correctAnswer: "PUBG"
        }
    ]
};


export const playstationHistoryQuiz = {
    quizPath: 'playstationhistory',
    quizHeader: "Are you a true PlayStation fan? Take this ultimate PlayStation history quiz to test your knowledge about the evolution of the beloved gaming brand!",
    image: "/images/quizzes/playstation-history.jpg",
    data: [
        {
            question: "In what year was the original PlayStation console released in Japan?",
            options: ["1993", "1994", "1995", "1996"],
            correctAnswer: "1994"
        },
        {
            question: "Which game is considered the best-selling game for the original PlayStation?",
            options: ["Final Fantasy VII", "Gran Turismo", "Tekken 3", "Crash Bandicoot"],
            correctAnswer: "Gran Turismo"
        },
        {
            question: "Who developed the PlayStation console in partnership with Sony before a split led to the creation of a competing console?",
            options: ["Nintendo", "Sega", "Atari", "Microsoft"],
            correctAnswer: "Nintendo"
        },
        {
            question: "What was the name of Sony's online service for PlayStation 2?",
            options: ["PSN", "Sony Online", "PlayOnline", "PlayStation Network"],
            correctAnswer: "PlayOnline"
        },
        {
            question: "Which PlayStation console introduced the concept of trophies to track player achievements?",
            options: ["PlayStation 2", "PlayStation 3", "PlayStation 4", "PlayStation 5"],
            correctAnswer: "PlayStation 3"
        },
        {
            question: "What was the name of the handheld console that Sony released in 2004 to compete with the Nintendo DS?",
            options: ["PlayStation Portable (PSP)", "PS Vita", "PlayStation Go", "PlayStation Mini"],
            correctAnswer: "PlayStation Portable (PSP)"
        },
        {
            question: "Which iconic game character debuted alongside the PlayStation brand and became a mascot?",
            options: ["Crash Bandicoot", "Spyro the Dragon", "Ratchet", "Sly Cooper"],
            correctAnswer: "Crash Bandicoot"
        },
        {
            question: "Which PlayStation 4 exclusive was awarded Game of the Year in 2018 at The Game Awards?",
            options: ["God of War", "Horizon Zero Dawn", "Uncharted 4: A Thief's End", "The Last of Us Part II"],
            correctAnswer: "God of War"
        },
        {
            question: "What feature did the PlayStation 2 have that helped make it the best-selling console of all time?",
            options: ["DVD Player", "Online Play", "Bluetooth Controllers", "Motion Control"],
            correctAnswer: "DVD Player"
        },
        {
            question: "What was the code name for the PlayStation 4 during its development?",
            options: ["Orbis", "Morpheus", "Neo", "Andromeda"],
            correctAnswer: "Orbis"
        }
    ]
};

export const matchGameWithDeveloperQuiz = {
    quizPath: 'matchgamewithdeveloper',
    quizHeader: "Think you know which developers made some of the most iconic games? Take this quiz to match the games with their creators and prove your gaming industry expertise!",
    image: "/images/quizzes/match-game-developer.jpg",
    data: [
        {
            question: "Who developed 'The Legend of Zelda: Breath of the Wild'?",
            options: ["Nintendo", "Ubisoft", "Square Enix", "Sony Santa Monica"],
            correctAnswer: "Nintendo"
        },
        {
            question: "Which studio is behind the 'The Witcher' series?",
            options: ["CD Projekt Red", "BioWare", "Bethesda", "Rockstar Games"],
            correctAnswer: "CD Projekt Red"
        },
        {
            question: "Who developed 'Grand Theft Auto V'?",
            options: ["Rockstar Games", "Activision", "Ubisoft", "Electronic Arts"],
            correctAnswer: "Rockstar Games"
        },
        {
            question: "Which company created 'Halo: Combat Evolved'?",
            options: ["Bungie", "343 Industries", "Epic Games", "Infinity Ward"],
            correctAnswer: "Bungie"
        },
        {
            question: "Who is the developer of 'Dark Souls'?",
            options: ["FromSoftware", "Capcom", "Square Enix", "Bandai Namco"],
            correctAnswer: "FromSoftware"
        },
        {
            question: "Which studio developed 'Fortnite'?",
            options: ["Epic Games", "Respawn Entertainment", "Gearbox Software", "Blizzard Entertainment"],
            correctAnswer: "Epic Games"
        },
        {
            question: "Who developed 'The Last of Us'?",
            options: ["Naughty Dog", "Insomniac Games", "Santa Monica Studio", "Ubisoft"],
            correctAnswer: "Naughty Dog"
        },
        {
            question: "Which company is behind 'Assassin's Creed'?",
            options: ["Ubisoft", "Square Enix", "Electronic Arts", "CD Projekt Red"],
            correctAnswer: "Ubisoft"
        },
        {
            question: "Who developed 'Overwatch'?",
            options: ["Blizzard Entertainment", "Valve", "Riot Games", "Epic Games"],
            correctAnswer: "Blizzard Entertainment"
        },
        {
            question: "Which studio created 'Red Dead Redemption 2'?",
            options: ["Rockstar Games", "CD Projekt Red", "Bethesda", "Naughty Dog"],
            correctAnswer: "Rockstar Games"
        }
    ]
};



export const skyrimQuiz = {
    quizPath: 'skyrim',
    quizHeader: "Think you're the Dragonborn? Test your knowledge about Skyrim and its epic lore, quests, and characters!",
    image: "/images/quizzes/skyrim.jpg",
    data: [
        {
            question: "What is the name of the Dragonborn protagonist in Skyrim?",
            options: ["Dovahkiin", "Dragonheart", "Alduin", "Septim"],
            correctAnswer: "Dovahkiin"
        },
        {
            question: "Which city is home to the Thieves Guild in Skyrim?",
            options: ["Riften", "Solitude", "Whiterun", "Windhelm"],
            correctAnswer: "Riften"
        },
        {
            question: "What is the name of the leader of the Greybeards?",
            options: ["Paarthurnax", "Ulfric", "Arngeir", "Esbern"],
            correctAnswer: "Paarthurnax"
        },
        {
            question: "Which Daedric Prince is known as the 'Prince of Madness'?",
            options: ["Sheogorath", "Molag Bal", "Mehrunes Dagon", "Meridia"],
            correctAnswer: "Sheogorath"
        },
        {
            question: "What shout is used to summon a dragon?",
            options: ["Call Dragon", "Dragonrend", "Fire Breath", "Fus Ro Dah"],
            correctAnswer: "Call Dragon"
        },
        {
            question: "Who is the Jarl of Whiterun at the beginning of the game?",
            options: ["Balgruuf the Greater", "Ulfric Stormcloak", "Elisif the Fair", "Skald the Elder"],
            correctAnswer: "Balgruuf the Greater"
        },
        {
            question: "What is the name of the dragon that serves as the main antagonist in Skyrim?",
            options: ["Alduin", "Odahviing", "Mirmulnir", "Paarthurnax"],
            correctAnswer: "Alduin"
        },
        {
            question: "Which faction is dedicated to hunting vampires in Skyrim?",
            options: ["The Dawnguard", "The Blades", "The Companions", "The Dark Brotherhood"],
            correctAnswer: "The Dawnguard"
        },
        {
            question: "What is the reward for completing the quest 'The Black Star'?",
            options: ["Azura's Star", "Mehrunes' Razor", "The Ebony Blade", "The Wabbajack"],
            correctAnswer: "Azura's Star"
        },
        {
            question: "What race is known for their natural resistance to cold and ability to breathe underwater?",
            options: ["Argonian", "Nord", "Khajiit", "Dunmer"],
            correctAnswer: "Argonian"
        }
    ]
};

// Export other quiz data as needed
