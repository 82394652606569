import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../QuizData.css'; // Ensure the path is correct
import OutstreamVideoAd from './OutstreamVideoAd';

const Quiz = ({ quizData }) => {
    const navigate = useNavigate();
    const { questionIndex } = useParams();
    const currentQuestionIndex = parseInt(questionIndex, 10) || 0;

    const currentQuestion = quizData.data
        ? quizData.data[currentQuestionIndex]
        : quizData[currentQuestionIndex];

    const [selectedOption, setSelectedOption] = React.useState(null);
    const [correctAnswersCount, setCorrectAnswersCount] = React.useState(0);
    const [showResult, setShowResult] = React.useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleNext = () => {
        if (selectedOption === currentQuestion.correctAnswer) {
            setCorrectAnswersCount(correctAnswersCount + 1);
        }
    
        if (currentQuestionIndex < quizData.data.length - 1) {
            // Use quizPath without a leading slash and construct the path with a single slash
            navigate(`/quiz/${quizData.quizPath}/${currentQuestionIndex + 1}`);
        } else {
            setShowResult(true);
        }
        setSelectedOption(null);
    };
    

    if (!currentQuestion) {
        return <h2 className="quiz-result">Invalid question index. Please start the quiz from the beginning.</h2>;
    }

    if (showResult) {
        return (
            <div className="quiz-result">
                <h2>Quiz Completed!</h2>
                <p>You answered {correctAnswersCount} out of {quizData.data.length} questions correctly.</p>
            </div>
        );
    }

    return (
        <div className="quiz-container">
            <Helmet>
                <title>{quizData.quizHeader} - Quiz App</title>
                <meta property="og:title" content={quizData.quizHeader} />
                <meta property="og:image" content={quizData.Image} />
                {/* Additional optional meta tags for Twitter cards or other social media */}
            </Helmet>

            <h2 className="quiz-header-text">{quizData.quizHeader}</h2>
            <h3 className="quiz-header">Question {currentQuestionIndex + 1}: {currentQuestion.question}</h3>
            <div className="quiz-options">
    {currentQuestion.options.map((option, index) => (
        <label key={index} className={`quiz-option ${selectedOption === option ? 'selected' : ''}`}>
            <input
                type="radio"
                name="option"
                value={option}
                checked={selectedOption === option}
                onChange={() => handleOptionSelect(option)}
            />
            {option}
        </label>
    ))}
</div>


            <button className="quiz-button" onClick={handleNext} disabled={!selectedOption}>
                Next
            </button>
            <OutstreamVideoAd />
        </div>
    );
};

export default Quiz;
