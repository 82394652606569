import React, { useState, useRef } from 'react'
import '../NeonHorizon.css'
import BuyMeCoffee from './BuyMeCoffee';
import { Helmet } from 'react-helmet-async';
import OutstreamVideoAd from './OutstreamVideoAd';

const NeonHorizonWeb = () => {
    const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
    const iframeRef = useRef(null); // Reference to the iframe
  
    // Function to handle full-screen toggle
    const toggleFullScreen = () => {
      if (!isFullScreen) {
        // Enter full-screen mode
        if (iframeRef.current) {
          iframeRef.current.requestFullscreen()
            .then(() => setIsFullScreen(true))
            .catch(err => console.error("Failed to enter full-screen mode:", err));
        }
      } else {
        // Exit full-screen mode
        document.exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch(err => console.error("Failed to exit full-screen mode:", err));
      }
    };
  
    return (
      <div className='NeonHorizonContainer'>
            <Helmet>
                <title>Arrow Dash - Dodge Pillars in This Exciting Challenge!</title>
                <meta property="og:title" content="Arrow Dash - Dodge Pillars in This Exciting Challenge!" />
                <meta 
                    property="og:description" 
                    content="Navigate as a nimble arrow through an endless series of pillars. Test your reflexes in Arrow Dash, inspired by the classic Flappy Bird!" 
                />
                <meta property="og:image" content="/images/games/arrow-dash.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dissonantdruid.com/arrow-dash" />
            </Helmet>
        {/* Iframe for the game */}
        <iframe
          ref={iframeRef}
          src="/arrow-dash-web/arrow_dash.html"
          title="Desktop Game"
          className='NeonHorizonWindow'
        />
    
    <div className='GameTitle'>ARROW DASH</div>

        {/* Full-Screen Toggle Button*/}
        <button onClick={toggleFullScreen} className='FullScreenButton'>
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>

        <div className='BuyCoffee'>
          <div className='CoffeeText'>
            Enjoying the game? Buy us a coffee to show your support!
          </div>
          <BuyMeCoffee />
        </div>
        <OutstreamVideoAd />
      </div>
    )
}

export default NeonHorizonWeb