import React from 'react'
import '../App.css'

const DataDeletePage = () => {
  return (
    <div className='textContainer'>
        <div>
        <br/><br/><br/><br/><br/><br/><br/><br/>
            Mobile games from Dissonant Druid do not collect <br/>
            any data from the user, so there is no data to delete.<br/><br/>

            Privacy Policy <br/><br/>

Last Updated: Aug 8, 2024 <br/>

Thank you for visiting our website and playing our games! Your privacy is important to us, and we are committed to protecting it. <br/>
This Privacy Policy outlines how we handle your information when you use our games.
<br/><br/>
1. Information We Do Not Collect
We do not collect, store, or process any personal data from users who visit our website or play our games. This includes but is not limited to names, email addresses, phone numbers, or any other personally identifiable information.
<br/><br/>
2. Advertisements
Our website and games may display advertisements provided by third-party advertisers, such as Google AdMob. These advertisers may use cookies, web beacons, and other technologies to collect non-personal information such as device type, network provider, or general location to provide relevant advertisements.
<br/><br/>
Please note that while we do not collect any personal data, these third-party services may collect certain information as part of their ad-serving process. We recommend reviewing the privacy policies of these advertisers for more information on their data collection and usage practices.
<br/><br/>
<br/>
3. Children’s Privacy
Our games are not intended for children under the age of 13. We do not knowingly collect any personal information from children under this age. If we become aware that a child under the age of 13 has provided us with personal information, we will take steps to delete such information from our systems.
<br/><br/>
4. Changes to This Privacy Policy
We may update this Privacy Policy from time to time. Any changes will be reflected with a new "Last Updated" date at the top of this policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information. 
        </div>
    </div>
  )
}

export default DataDeletePage