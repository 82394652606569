import React from 'react';
import { Link } from 'react-router-dom';
import * as QuizData from '../Components/QuizData'; // Adjust the path if necessary
import '../QuizList.css'
import { Helmet } from 'react-helmet';
import OutstreamVideoAd from './OutstreamVideoAd';
import path from 'path-browserify';

const QuizList = () => {
    const quizzes = [
        {
            name: "Silent Hill 2 Quiz",
            path: QuizData.silentHill2RemakeQuiz.quizPath,
            header: QuizData.silentHill2RemakeQuiz.quizHeader,
        },
        {
            name: "Minecraft Quiz",
            path: QuizData.minecraftQuiz.quizPath,
            header: QuizData.minecraftQuiz.quizHeader,
        },
        {
            name: "Gaming History Quiz",
            path: QuizData.gamingHistoryQuiz.quizPath,
            header: QuizData.gamingHistoryQuiz.quizHeader,
        },
        {
            name: "History of Playstation Quiz",
            path: QuizData.playstationHistoryQuiz.quizPath,
            header: QuizData.playstationHistoryQuiz.quizHeader,
        },
        {
            name: "Can You Matche These Games With Their Developers?",
            path: QuizData.matchGameWithDeveloperQuiz.quizPath,
            header: QuizData.matchGameWithDeveloperQuiz.quizHeader,
        },
        {
            name: "Think you're the Dragonborn? Test your knowledge about Skyrim and its epic lore, quests, and characters!",
            path: QuizData.skyrimQuiz.quizPath,
            header: QuizData.skyrimQuiz.quizHeader,
        }
        // Add more quizzes here as needed
    ];

    return (
        <div className="quiz-list-container">
            <Helmet>
                <title>Test Your Knowledge With Our Gaming Quizzes!</title>
                <meta property="og:title" content="Test Your Knowledge With Our Gaming Quizzes!" />
                <meta property="og:description" content="Explore our collection of fun and challenging gaming quizzes to test your knowledge across a variety of games!" />
            </Helmet>
            <h1>Test Your Game Knowledge With Our Gaming Quizzes</h1>
            <ul className="quiz-list">
                {quizzes.map((quiz, index) => (
                    <React.Fragment key={index}>
                        <li className="quiz-item">
                            <Link to={`/quiz/${quiz.path}/0`} className="quiz-link">
                                <h2>{quiz.name}</h2>
                                <p>{quiz.header}</p>
                            </Link>
                        </li>
                        {(index + 1) % 2 === 0 && (
                            <li className="ad-item">
                                {/* Insert your ad component here */}
                                <OutstreamVideoAd />
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
    
};

export default QuizList;
