import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../NavbarMenu.css';

export default function NavbarMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="burger-menu" onClick={toggleMenu} aria-expanded={isOpen} aria-controls="menu-list">
        &#9776;
      </div>
      <ul className={`menu ${isOpen ? 'open' : 'closed'}`} id="menu-list">
        <li><Link to="/" className="ListItem" onClick={toggleMenu}>Home</Link></li>
        <li><Link to="/about" className="ListItem" onClick={toggleMenu}>About</Link></li>
        <li><Link to="/games" className="ListItem" onClick={toggleMenu}>Games</Link></li>
        <li><Link to="/contact" className="ListItem" onClick={toggleMenu}>Contact</Link></li>
        <li><Link to="/blog-updates" className="ListItem" onClick={toggleMenu}>Blog</Link></li>
        <li><Link to="/privacy-notice" className="ListItem" onClick={toggleMenu}>Privacy Policy</Link></li>
        <li><Link to="/quiz-list" className='ListItem' onClick={toggleMenu}>Quizzes</Link></li>
      </ul>
    </nav>
  );
}
