// GameSelection.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../GameSelection.css'

const GameSelection = () => {
  const navigate = useNavigate();

  return (
    <div className="game-selection-container">
      <h1 className="game-selection-title">Final Void</h1>
      <div className="button-group">
        <button
          className="game-button desktop-button"
          onClick={() => navigate('/desktop-game')}
        >
          Play Desktop Version
        </button>
        {/* Direct Link Ad 
        <button
          className="game-button mobile-button"
          onClick={() => navigate('/mobile-game')}
        >
          Play Mobile Version
        </button>
        */}
      </div>
    </div>
  );
};

export default GameSelection;
