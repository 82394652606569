import React, { useState, useRef } from 'react';
import '../FullScreenButton.css'
import BuyMeACoffe from '../Components/BuyMeCoffee'
import { Helmet } from 'react-helmet-async';
import OutstreamVideoAd from './OutstreamVideoAd';



const PaddleWar = () => {
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
  const iframeRef = useRef(null); // Reference to the iframe

  // Function to handle full-screen toggle
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full-screen mode
      if (iframeRef.current) {
        iframeRef.current.requestFullscreen()
          .then(() => setIsFullScreen(true))
          .catch(err => console.error("Failed to enter full-screen mode:", err));
      }
    } else {
      // Exit full-screen mode
      document.exitFullscreen()
        .then(() => setIsFullScreen(false))
        .catch(err => console.error("Failed to exit full-screen mode:", err));
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', paddingTop: '100px' }}>
            <Helmet>
                <title>Paddle War - A Modern Take on Classic Pong</title>
                <meta property="og:title" content="Paddle War - A Modern Take on Classic Pong" />
                <meta 
                    property="og:description" 
                    content="Experience Paddle War, a fresh spin on the classic Pong game. Compete in fast-paced paddle battles and test your reflexes!" 
                />
                <meta property="og:image" content="/images/games/paddle-war.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dissonantdruid.com/paddle-war" />
            </Helmet>
      {/* Iframe for the game */}
      <iframe
        ref={iframeRef}
        src="/pong/index.html"
        title="Desktop Game"
        style={{ width: '100%', height: '80vh', border: 'none' }} // Style adjustment
      />

            {/* Full-Screen Toggle Button */}
      <button onClick={toggleFullScreen} className='FullScreenButton'>
        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
      </button>

    <div className='BuyMeCoffee'>
      <div className='BuyCoffeeText'>
      Enjoying the game? Let us know by buying us a coffee!
      </div>
      <BuyMeACoffe />
    </div>
    <OutstreamVideoAd />
    </div>
  );
};

export default PaddleWar;
