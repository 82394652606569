import React, { useEffect, useState } from 'react';
import frontMatter from 'front-matter';
import { marked } from 'marked';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  LinkedinIcon,
} from 'react-share';
import '../BlogPost.css';

const BlogPost = () => {
  const { postId } = useParams();
  console.log("Post ID:", postId);
  const [post, setPost] = useState(null);

  const currentUrl = window.location.href;
  console.log("Current URL:", currentUrl);

  const defaultImage = 'https://dissonantdruid.com/Updates/image/share_image.png';  // Path to your default image


  useEffect(() => {
    const fetchPost = async () => {
      const response = await fetch(`/Updates/${postId}.md`);
      if (!response.ok) {
        console.error(`Error fetching post: ${response.statusText}`);
        return;
      }
      const text = await response.text();
      const { attributes, body } = frontMatter(text);
      console.log('Attributes:', attributes);
      const content = marked(body);
      setPost({ ...attributes, content });
      
    };

    fetchPost();
  }, [postId]);

  if (!post) return <div>Loading...</div>;

  const parseDate = (dateString) => {
    const [month, day, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
  };

  const formattedDate = post.date 
    ? parseDate(post.date).toLocaleDateString() 
    : 'Date not available';


    // Debugging date issues
    console.log('Post Date:', post.date);
    console.log('Parsed Date:', new Date(post.date));

  return (
    <div className="BlogPost">

      <Helmet>
        <title>{post.title}</title>
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt || post.title} />
        <meta property="og:image" content={defaultImage} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="article" />
      </Helmet>

      <div className="BlogEntry">
        <h1 className="BlogTitle">{post.title}</h1>
        <div className="BlogContent" dangerouslySetInnerHTML={{ __html: post.content }} />
        <p className='AuthorName'>{post.author}</p>
        <p className="BlogDate">{formattedDate}</p>

        <div className="SocialShareButtons">
          <FacebookShareButton url={currentUrl} quote={post.title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={currentUrl} title={post.title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <RedditShareButton url={currentUrl} title={post.title}>
            <RedditIcon size={32} round />
          </RedditShareButton>
          <LinkedinShareButton url={currentUrl} title={post.title}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

      </div>

    </div>
  );
};

export default BlogPost;
