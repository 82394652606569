// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import  {  getDatabase, ref, update, set, increment } from 'firebase/database'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAM50dOwJkazT2cVfVQr_ngMsJ-mPS7128",
  authDomain: "dissonant-druid.firebaseapp.com",
  databaseURL: "https://dissonant-druid-default-rtdb.firebaseio.com",
  projectId: "dissonant-druid",
  storageBucket: "dissonant-druid.appspot.com",
  messagingSenderId: "996391514592",
  appId: "1:996391514592:web:8061304a43460c919afb85",
  measurementId: "G-GMWT9VP2Z2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const database = getDatabase(app)


export { db, database, ref, update, set, increment };